<template>
  <v-row>
    <v-col cols="12" lg="4">
      <form-field-text v-model="obWarehouse.name" />
    </v-col>

    <v-col cols="12" lg="4">
      <form-field-text v-model="obWarehouse.code" label="code" />
    </v-col>

    <v-col cols="12" lg="4">
      <account-code-select v-model="sCodeAccount" />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Vue, Component, VModel } from "vue-property-decorator";
import { Warehouse } from "@planetadeleste/vue-mc-gw";
import AccountCodeSelect from "@/modules/accounts/components/AccountCodeSelect.vue";

@Component({
  components: { AccountCodeSelect },
})
export default class WarehousesSettings extends Vue {
  @VModel({ type: Object, default: () => new Warehouse() })
  obWarehouse!: Warehouse;

  get sCodeAccount() {
    return this.obWarehouse.get("code_account", "") as string;
  }

  set sCodeAccount(sValue: string) {
    this.obWarehouse.set("code_account", sValue);
  }
}
</script>
